import './ReserveForm.css'
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, createRef } from 'react';
import DatePicker from "react-datepicker";
import emailjs from '@emailjs/browser';

import PetForm from './PetForm';
import Popup from './Popup';
import Loading from './Loading.js';

import Add from '../images/add.png'
import Minus from '../images/minus.png'

const ReserveForm = () => {
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [nextDate, setNextDate] = useState(new Date());
    const [petList, setPetList] = useState([]);
    
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const issues = [
        "Displays aggression, unfriendly behaviour, or is under-socialised towards humans or other dogs.",
        "Has a history of excessive barking or aggressive vocalisation.",
        "Suffers from contagious illnesses or has been in contact with contagious dogs recently.",
        "Experiences severe separation anxiety or exhibits distress when separated.",
        "Shows signs of food aggression or possessiveness.",
        "Has known skin allergies (minor or major) that are not contagious.",
        "Has known food allergies or specific dietary requirements.",
        "Has a history of fighting or has been involved in a dog fight before.",
        "Is boarding for the first time.",
        "Any other additional information to disclose."
    ]
    
    const triggerTextbox = (name) => {
            // console.log(name)
            if (document.querySelector(`input[name=${name}]:checked`) 
                && document.querySelector(`input[name=${name}]:checked`).value == 'Yes') {
                if (!document.getElementById(name + '_text')) {
                    // console.log('addAllergyTextBox')
                    var node = document.createElement('input')
                    var grid = document.getElementById(`${name}-grid`)
                    node.setAttribute("class","reserve-form-input-text");
                    node.setAttribute("id", name + '_text');
                    node.setAttribute("name", name + '_text');
                    node.setAttribute("type","text");
                    if (document.querySelector(`input[name=${name}]`).classList.contains('required')) {
                        grid.insertBefore(node, grid.lastChild)
                    } else {
                        grid.appendChild(node)
                    }
                }
            } else {
                // console.log('noAllergy')
                if (document.getElementById(name + '_text')) {
                    // console.log('removeAllergyTextBox')
                    document.getElementById(name + '_text').remove();
                    if (document.getElementById(name + '_text_required')) {
                        // console.log('removeAllergyTextBox')
                        document.getElementById(name + '_text_required').remove();
                    }
                }
            }
        }


    const getNextDate = (date) => {
        let temp = new Date(date);
        temp.setDate(temp.getDate() + 1)
        return temp
    }

    const changeStartDate = (date) => {
        setStartDate(date);

        
        if (date) {
            if (endDate < getNextDate(date)) {
                setEndDate(getNextDate(date))
            }
        }
    }
    
    const changeEndDate = (date) => {
        setEndDate(date);

        if (date) {
            let temp = new Date(date);
            temp.setDate(temp.getDate() - 1)
            if (startDate > temp) {
                setStartDate(temp)
            }
        }
    }

    useEffect(() => {
        let next = getNextDate(new Date());
        setNextDate(next);

        setPetList([...petList, createRef()]);

        var start = startDate;
        var end = next;
        if (searchParams.get('startDate')) {
            var date = new Date(searchParams.get('startDate'))
            if (!isNaN(date) && date > startDate) {
                start = date
            }
        } 

        if (searchParams.get('endDate')) {
            var date = new Date(searchParams.get('endDate'))
            if (!isNaN(date) && date > next) {
                end = date
            }
        }

        if (start > end) {
            end = getNextDate(start);
        }

        start.setHours(12)
        start.setMinutes(0)
        start.setSeconds(0)
        end.setHours(12)
        end.setMinutes(0)
        end.setSeconds(0)

        setStartDate(start);
        setEndDate(end);
    }, [])

    const alterPetList = (num) => {
        if (num == 1) {
            setPetList([...petList, PetForm])
        } else if (num == -1) {
            setPetList(petList.slice(0, petList.length - 1))
        }
        
    }

    const checkRequiredText = (flag, name) => {
        var element = document.querySelector(`input[name=${name}]`);
        if (!element.value) {

            if (!element.classList.contains('required')) {
                element.classList.add("required");
                
                var rq = document.createElement('div')
                rq.setAttribute('class', 'required-text')
                rq.setAttribute('id', name + '_required')
                rq.textContent = "*Required"
                element.parentElement.appendChild(rq);
            }

            if (flag) {
                window.scroll(element.offsetLeft, element.offsetTop - window.innerHeight * 0.3);
            }
            flag = false
        } else if (element.classList.contains('required')) {
            element.classList.remove("required");
            element.parentElement.removeChild(element.parentElement.lastChild);
        }
        return flag;
    }

    
    const checkRequiredDate = (flag, name) => {
        var element = document.querySelector(`input[name=${name}]`);
        if (!element.value) {

            if (!element.parentElement.classList.contains('required')) {
                element.classList.add("required");
                element.parentElement.classList.add("required");
                
                var rq = document.createElement('div')
                rq.setAttribute('class', 'required-text')
                rq.setAttribute('id', name + '_required')
                rq.textContent = "*Required"
                element.parentElement.appendChild(rq);
            }

            if (flag) {
                window.scroll(element.parentNode.offsetLeft, element.parentNode.offsetTop - window.innerHeight * 0.3);
            }
            flag = false
        } else if (element.parentElement.classList.contains('required')) {
            element.parentElement.classList.remove("required");
            element.parentElement.removeChild(element.parentElement.lastChild);
        }
        return flag;
    }

    const checkRequiredRadio = (flag, name) => {
        var element = document.querySelector(`input[name=${name}]`);
        if (!document.querySelector(`input[name=${name}]:checked`)) {
            if (!element.classList.contains('required')) {
                element.classList.add("required");
                
                var rq = document.createElement('div')
                rq.setAttribute('class', 'required-text')
                rq.setAttribute('id', name + '_required')
                rq.textContent = "*Required"
                element.parentElement.parentNode.parentNode.appendChild(rq);
            }

            if (flag) {
                window.scroll(element.offsetLeft, element.offsetTop - window.innerHeight * 0.3);
            }
            flag = false
        } else if (element.classList.contains('required')) {
            element.classList.remove("required");
            element.parentElement.parentElement.parentElement.removeChild(element.parentElement.parentElement.parentElement.lastChild);
        }
        return flag;
    }

    const submitForm = (e) => {
        // console.log('submit')
        e.preventDefault(e)
        setLoading(true)

        var flag = true;
        const ownerField = [
            {name: "ownerName", type:"text", label: "Owner's Name"}
            ,{name: "phoneNumber", type:"text", label: "Phone Number"}
            ,{name: "email", type:"text", label: "Email"}
            ,{name: "address", type:"text", label: "Address"}
            ,{name: "pickup", type:"radio", label: "Pickup Services"}
            ,{name: "dropoff", type:"radio", label: "Dropoff Services"}
        ];

        const petField = [
            {name: "petName", type:"text", label: "Pet's Name"}
            ,{name: "birthDate", type:"date", label: "DOB"}
            ,{name: "breed", type:"text", label: "Breed"}
            ,{name: "gender", type:"radio", label: "Gender"}
            ,{name: "meal", type:"text", label: "Meal"}
            ,{name: "weight", type:"text", label: "Weight(kg)"}
            ,{name: "NS", type:"radio", label: "Neutered/Spayed"}
            ,{name: "PT", type:"radio", label: "Potty Training"}
        ];

        const issueField = issues.map((issue, ind) => { return {name: `issue${ind}`, type:"radio", label: issue}})
        
        flag = checkRequiredDate(flag, `checkin`);
        flag = checkRequiredDate(flag, `checkout`);
        ownerField.forEach((field) => {
            if (field.type == "text") {
                if (field.name == "address") {
                    if ((document.querySelector(`input[name=pickup]:checked`) 
                        && document.querySelector(`input[name=pickup]:checked`).value == 'Yes')
                        || (document.querySelector(`input[name=dropoff]:checked`) 
                        && document.querySelector(`input[name=dropoff]:checked`).value == 'Yes')){
                            flag = checkRequiredText(flag, field.name);
                        } else {
                            var element = document.querySelector(`input[name=address]`);
                            if (element.classList.contains('required')) {
                                element.classList.remove("required");
                                element.parentElement.removeChild(element.parentElement.lastChild);
                            }
                        }
                } else {
                    flag = checkRequiredText(flag, field.name);
                }
            } else if (field.type == "radio") {
                flag = checkRequiredRadio(flag, field.name);
            }
        })

        var ind = 0
        while (ind < petList.length) {
            petField.forEach((field) => {
                if (field.type == "text") {
                    flag = checkRequiredText(flag, `${field.name}_${ind}`);
                } else if (field.type == "radio") {
                    flag = checkRequiredRadio(flag, `${field.name}_${ind}`);
                } else if (field.type == "date") {
                    flag = checkRequiredDate(flag, `${field.name}_${ind}`);
                }
            })

            ind++;
        }

        issueField.forEach((field) => {
            flag = checkRequiredRadio(flag, field.name);
            // if (document.querySelector(`input[name=${field.name}_text]`)) {
            //     flag = checkRequiredText(flag, field.name + '_text')
            // } else if (document.querySelector(`input[name=${field.name}]:checked`)) {
            //     var allergyInput = document.querySelector(`input[name=${field.name}]`);
            //     if (allergyInput.parentElement.parentElement.parentElement.childNodes.length == 3) {
            //         allergyInput.parentElement.parentElement.parentElement.lastChild.remove()
            //     }
            // }
        })

        if (!document.querySelector('input[name=reserve_form_agreement]').checked) {
            flag = false
            if (!document.getElementById('reserve_form_agreement_required')) {
                var rq = document.createElement('div')
                rq.setAttribute('class', 'required-text')
                rq.setAttribute('id', 'reserve_form_agreement_required')
                rq.textContent = "*Required"
                document.querySelector('input[name=reserve_form_agreement]').parentElement.appendChild(rq);
            }
        } else {
            if (document.getElementById('reserve_form_agreement_required')) {
                document.getElementById('reserve_form_agreement_required').remove()
            }
        }

        var emailEle = document.querySelector('input[name=email]');
        if (emailEle.classList.contains('invalid')) {
            emailEle.classList.remove("invalid");
            emailEle.parentElement.childNodes.forEach((nodes) => {
                if (nodes.classList.contains('invalid-text')) {
                    nodes.parentElement.removeChild(nodes)
                }
            })

        }

        if (flag) {
            var pattern = /\S+@\S+\.\S+/i;
            if (!emailEle.value.match(pattern)) {
                emailEle.classList.add("invalid");
                
                var rq = document.createElement('div')
                rq.setAttribute('class', 'invalid-text')
                rq.textContent = "*Invalid email"
                emailEle.parentElement.appendChild(rq);
                window.scroll(emailEle.offsetLeft, emailEle.offsetTop - window.innerHeight * 0.3);
                setLoading(false)
            } else {
                var ownerDetail = ""
                ownerField.forEach((field) => {
                    if (field.type == "text") {
                        ownerDetail = ownerDetail.concat(`${field.label}: ${document.querySelector(`input[name=${field.name}]`).value}\n`)
                    } else if (field.type == "radio") {
                        ownerDetail = ownerDetail.concat(`${field.label}: ${document.querySelector(`input[name=${field.name}]:checked`).value}\n`)
                    }
                })

                var petDetail = {}
                var ind = 0
                while (ind < petList.length) {
                    var petString = `Pet ${ind+1}\n`
                    petField.forEach((field) => {
                        if (field.type == "text") {
                            petString = petString.concat(`${field.label}: ${document.querySelector(`input[name=${field.name}_${ind}]`).value}\n`)
                        } else if (field.type == "radio") {
                            petString = petString.concat(`${field.label}: ${document.querySelector(`input[name=${field.name}_${ind}]:checked`).value}\n`)
                        } else if (field.type == "date") {
                            var date = document.querySelector(`input[name=${field.name}_${ind}]`).value
                            petString = petString.concat(`${field.label}: ${date.substr(0,2)} ${month[parseInt(date.substr(3,2)) - 1]} ${date.substr(6,4)} \n`)
                        }
                    })
                    petDetail[ind] = petString + `\n`
                    ind++;
                }

                var issueDetail = ""
                issueField.forEach((field, ind) => {
                    if (document.querySelector(`input[name=${field.name}]:checked`).value == 'Yes') {
                        issueDetail = issueDetail.concat(`${ind+1}. ${field.label}\n ${document.querySelector(`input[name=${field.name}_text]`).value}\n \n`)
                    }
                })

                var emailParams = {
                    owner_name: document.querySelector('input[name=ownerName]').value,
                    owner_email: emailEle.value,
                    checkin: `${startDate.getDate()} ${month[startDate.getMonth()]} ${startDate.getFullYear()} - ${startDate.toLocaleTimeString('en-US')}`,
                    checkout: `${endDate.getDate()} ${month[endDate.getMonth()]} ${endDate.getFullYear()} -  ${endDate.toLocaleTimeString('en-US')}`,
                    owner_details: ownerDetail,
                    pet_details0: petDetail[0],
                    pet_details1: petDetail[1],
                    pet_details2: petDetail[2],
                    pet_details3: petDetail[3],
                    pet_details4: petDetail[4],
                    issue_detail: issueDetail
                }

                // console.log(emailParams)
                // setLoading(false)

                emailjs.send('service_fwh9a7a', 'template_ee9gzcb', emailParams, '4lK72NfzsuXdOuUe7')
                .then((result) => {
                    // console.log(result.text);
                    setLoading(false)
                    setSubmitted(true);
                    emailjs.send('service_jeeerwy', 'template_skakin1', emailParams, 'JZaAEMmIqM1f0XieQ')
                    setTimeout(() => {
                        window.location.reload(true)
                    }, 1500)
                }, (error) => {
                    // console.log(error.text);
                    setLoading(false)
                    setError(true);
                    setTimeout(() => {
                        setError(false);
                    }, 1500)
                });
            }

        } else {
            setLoading(false)
        }

    }

    return (
        <>
            <Loading loading={loading} />
            {submitted ? <Popup message={'Form submitted successfully.'}/> : ''}
            {error ? <Popup message={'Form submission failed. Please try again later.'}/> : ''}
            <form className='reserve-form-grid colored-background'  onSubmit={(e) => {submitForm(e);}}>
                
            <div className='reserve-form-note'>
            ❗ NOTE: Our standard check-in and check-out hours are between 10:00 AM and 7:00 PM daily. Arrivals outside of these hours will incur an additional fee. Thank you. 🙏
            </div>

                <div className='reserve-form-row reserve-form-two-in-a-row'>
                    <div className='reserve-form-field'>
                        <div className='reserve-form-label'>Check In</div>
                        <DatePicker
                                dateFormat="dd/MM/yyyy hh:mm aa"
                                selected={startDate}
                                onChange={changeStartDate}
                                minDate={new Date()}
                                className="reserve-form-input-text calendar-icon"
                                showTimeSelect
                                name='checkin'
                                />
                    </div>
                    
                    <div className='reserve-form-field'>
                        <div className='reserve-form-label'>Check Out</div>
                        <DatePicker
                            dateFormat="dd/MM/yyyy hh:mm aa"
                            selected={endDate}
                            onChange={changeEndDate}
                            minDate={nextDate}
                            className="reserve-form-input-text calendar-icon"
                            showTimeSelect  
                            name='checkout'
                            />
                    </div>
                </div>

                <div className='reserve-form-row reserve-form-two-in-a-row'>
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Your Name</div>
                        <input 
                            className='reserve-form-input-text'
                            id="ownerName" name="ownerName"
                            type="text"/>
                    </div>
                    
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Phone Number</div>
                        <input 
                            className='reserve-form-input-text'
                            id="phoneNumber" name="phoneNumber"
                            type="text"/>
                    </div>
                </div>
                
                
            <div className='reserve-form-note'>
            ❗ NOTE: Please provide an active email address. We will send a confirmation email with a useful note. Thank you. 🙏
            </div>
                <div className='reserve-form-row'>
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Email</div>
                        <input 
                            className='reserve-form-input-text'
                            id="email" name="email"
                            type="text"/>
                    </div>
                </div>


                <div className='reserve-form-row'>
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Address</div>
                        <input 
                            className='reserve-form-input-text'
                            id="address" name="address"
                            type="text"/>
                    </div>
                </div>

                
                <div className='reserve-form-row'>
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Pickup Service</div>
                        <div className='reserve-form-three-in-a-row'>
                            <div className='reserve-form-input-radio'>
                                <input 
                                    type='radio' id="pickup_yes" name="pickup" value="Yes"/>
                                <label for="pickup_yes">Yes</label>
                            </div>
                            <div className='reserve-form-input-radio'>
                                <input 
                                    type='radio' id="pickup_no" name="pickup" value="No"/>
                                <label for="pickup_no">No</label>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='reserve-form-row'>
                    <div className='reserve-form-field'> 
                        <div className='reserve-form-label'>Dropoff Service</div>
                        <div className='reserve-form-three-in-a-row'>
                            <div className='reserve-form-input-radio'>
                                <input 
                                    type='radio' id="dropoff_yes" name="dropoff" value="Yes"/>
                                <label for="dropoff_yes">Yes</label>
                            </div>
                            <div className='reserve-form-input-radio'>
                                <input 
                                    type='radio' id="dropoff_no" name="dropoff" value="No"/>
                                <label for="dropoff_no">No</label>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className='reserve-form-field'> 
                    <div className='reserve-form-label'>Number of Pet(s)</div>
                    <div className='reserve-form-pet-number-row'>
                        {petList.length > 1 ? <img src={Minus} className='reserve-form-minus-icon' onClick={() => alterPetList(-1)}/> : <div/>}
                        <div className='reserve-form-pet-number-grid'>{petList.length}</div>
                        {petList.length < 5 ?<img src={Add} className='reserve-form-add-icon' onClick={() => alterPetList(1)}/> : <div/>}
                    </div>
                </div>

                {petList.map((form, index) => {return <PetForm ref={form} ind={index}/>})}


                <hr/>
                <div className='reserve-form-issues-title'>
                    Please check or disclose if your dog:
                </div>

                {issues.map((issue, ind) => {
                    return <>
                        <div className='reserve-form-row'>
                            <div className='reserve-form-field' id={`issue${ind}-grid`}> 
                                <div className='reserve-form-label'>{ind+1}. {issue}</div>
                                <div className='reserve-form-three-in-a-row'>
                                    <div className='reserve-form-input-radio'>
                                        <input 
                                            type='radio' id={`issue${ind}_yes`} name={`issue${ind}`} value="Yes" onClick={() => triggerTextbox(`issue${ind}`)}/>
                                        <label for={`issue${ind}_yes`}>Yes</label>
                                    </div>
                                    <div className='reserve-form-input-radio'>
                                        <input 
                                            type='radio' id={`issue${ind}_no`} name={`issue${ind}`} value="No" onClick={() => triggerTextbox(`issue${ind}`)}/>
                                        <label for={`issue${ind}_no`}>No</label>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </>
                })}

                <div className='reserve-form-note'>
                    For dogs that may have the abovementioned issues, a Meet and Greet session and/or a One-Night Trial Stay are REQUIRED to ensure a positive experience for all.
                    <br/><br/>
                </div>

                <div className='reserve-form-note'>
                    ❗ IMPORTANT ❗ <br/>
                    Failure to disclose relevant information may result in the inability to accommodate your dog for boarding. If undisclosed issues arise during the boarding period, we ask that you return your dog as soon as possible. Alternatively, we may need to transfer your dog to another reputable pet shelter. In such cases, you will be responsible for covering the shelter costs, transportation fees, and any additional charges incurred due to accidents or incidents.
                </div>
                <div className='reserve-form-note'>
                    We greatly appreciate your honesty and cooperation in providing a safe and comfortable environment for all boarding dogs.

                </div>


                
                <div className='reserve-form-aggrement-grid'>
                    <input type='checkbox' id='reserve_form_agreement' name='reserve_form_agreement'/>
                    I understand and agree to the above terms and conditions.
                </div>

                <div className='reserve-form-submit-grid'>
                    <input type="submit" className="reserve-form-submit" onSubmit={(e) => {submitForm(e)}}/>
                </div>
            </form>
        </>
    )
}

export default ReserveForm